import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ActiveAuditResponse } from '../../services/AuditQueue';
import { FormGroup, FormControl } from '@angular/forms';
import { Helpers } from '../../_shared/helpers';
import { AuthService } from '../../auth/auth.service';
import { GridDataResult } from '@progress/kendo-angular-grid';

const matches = (el, selector) => (el.matches || el.msMatchesSelector).call(el, selector);

@Component({
  selector: 'app-audit-email-list',
  templateUrl: './audit-email-list.component.html',
  styleUrls: ['./audit-email-list.component.scss'],
})
export class AuditEmailListComponent implements OnInit {
  @ViewChild('theGrid', { static: false })
  private recordGrid: GridComponent;

  get theRecords(): ActiveAuditResponse[] {
    return this.recordBookGrid;
  }

  @Input('theRecords')
  set theRecords(value: ActiveAuditResponse[]) {
    this.recordBookGrid = value;
    this.setRecords();
  }

  @Output()
  auditinvoice = new EventEmitter<ActiveAuditResponse>();

  public formGroup: FormGroup;
  gridView: GridDataResult;
  helpers: Helpers;
  editedRowIndex: number;

  skip = 0;
  pageSize = 150;
  recordBookGrid: ActiveAuditResponse[] = [];
  sort: SortDescriptor[] = [
    {
      field: 'invoiceDate',
      dir: 'asc',
    },
  ];

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.editedRowIndex = 0;
    this.helpers = new Helpers(this.authService);
  }

  private onSortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.setRecords();
  }

  // events
  public cellClickHandler({ isEdited, dataItem, rowIndex }): void {
    if (isEdited || (this.formGroup && !this.formGroup.valid)) {
      return;
    }

    this.updateRecord();
    this.formGroup = this.createFormGroup(dataItem);
    this.editedRowIndex = rowIndex;
    this.recordGrid.editRow(rowIndex, this.formGroup);
  }
  public documentClick(e: any): void {
    if (
      this.formGroup &&
      this.formGroup.valid &&
      !matches(e.target, '#theauditemailgrid tbody *, #theauditemailgrid .k-grid-toolbar .k-button')
    ) {
      this.updateRecord();
    }
  }

  // helpers
  public updateRecord() {
    if (this.formGroup && !this.formGroup.invalid) {
      // update the datasource
      const record = this.theRecords.find(({ id }) => id === this.formGroup.value.id);
      Object.assign(record, this.formGroup.value);

      // update the DB
      this.closeEditor();
    }
  }
  private closeEditor(): void {
    this.recordGrid.closeRow(this.editedRowIndex);
    this.formGroup = undefined;
    this.editedRowIndex = undefined;
  }
  private setRecords(): void {
    const records = orderBy(this.recordBookGrid, this.sort);
    this.gridView = {
      data: records.slice(this.skip, this.skip + this.pageSize),
      total: records.length,
    };
  }

  createFormGroup = (dataItem) =>
    new FormGroup({
      id: new FormControl(dataItem.id),
      aTeamNote: new FormControl(dataItem.aTeamNote),
    });
}
