import { ShipmentDocumentDTO } from '@/models/ShipmentDocument';

export class ManualProactiveResponse {
  id: number;
  carrierInvoiceID: number;
  isTestShipment: boolean;
  invoiceNumber: string;
  shipmentID: number;
  enterpriseID: number;
  invoiceDate: Date;
  owner: string;
  ownerAccount: string;
  primaryReference: string;
  pro: string;
  scac: string;
  carrierName: string;
  invoiceTotal: number;
  rates: ManualProactiveCustomerRateResponse[];
}

export class ManualProactiveCustomerRateResponse {
  id: number;
  analagousID: string;
  isSelected: boolean;
  dateCreated: Date;
  userCreated: Date;
  isOriginalSelected: boolean;
  total: number;
  scac: string;
  carrierName: string;
  radioButtonItem: string;
  quoteDate: Date;
  quoteTotal: number;
}

export class TLDocHoldProactiveResponse {
  id: number;
  carrierInvoiceID: string;
  isTestShipment: boolean;
  enterpriseID: number;
  shipmentID: number;
  invoiceNumber: string;
  carrierInvoiceTotal: number;
  customerInvoiceTotal: number;
  invoiceDate: Date;
  shipmentOriginActualDate: Date;
  shipmentOriginEarliestDate: Date;
  owner: string;
  ownerAccount: string;
  primaryReference: string;
  pro: string;
  externalTMSID: string;
  age: number;
  documentTypes: string;
  documents: ShipmentDocumentDTO[];
  customerInvoiceTotalString: string;
  carrierInvoiceTotalString: string;
}

export class SelectManualProactive {
  constructor(selection: ManualProactiveCustomerRateResponse, record: ManualProactiveResponse) {
    this.id = record.id;
    this.carrierInvoiceID = record.carrierInvoiceID;
    this.isTestShipment = record.isTestShipment;
    this.invoiceNumber = record.invoiceNumber;
    this.shipmentID = record.shipmentID;
    this.enterpriseID = record.enterpriseID;
    this.invoiceDate = record.invoiceDate;
    this.ownerAccount = record.ownerAccount;
    this.selectedAnalagousID = selection.analagousID;
  }
  id: number;
  carrierInvoiceID: number;
  isTestShipment: boolean;
  invoiceNumber: string;
  shipmentID: number;
  enterpriseID: number;
  invoiceDate: Date;
  ownerAccount: string;
  selectedAnalagousID: string;
}

export class ProactiveResponse {
  shipmentID: number;
  enterpriseID: number;
  invoiceNumber: string;
  invoiceDate: Date;
  invoiceDateString: string;
  isTestShipment: boolean;
  ownerAccount: string;
  owner: string;
  invoiceStatus: string;
  primaryReference: string;
  pro: string;
  invoiceID: number;
  id: number;
  carrierInvoiceID: number;
  sent: boolean;
  customerInvoiceTotal: number;
  carrierInvoiceTotal: number;
  invoiceGP: number;
}

export class ReleaseCustomerInvoice {
  id: number;
  enterpriseID: number;
  enterpriseAccountNumber: string;
}

export class DisputeCustomerInvoice {
  id: number;
}

export class RevertCustomerInvoice {
  id: number;
}

export interface RevertActionResult {
  succeeded: number[];
  failed: number[];
}