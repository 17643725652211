import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddressViewComponent } from './address-view/address-view.component';
import { ConversionPipe } from './pipes/conversion.pipe';
import { ChargeTableComponent } from './charge-table/charge-table.component';
import { AccordionComponent } from './accordion/accordion.component';
import { AuditCarrierInvoiceComponent } from './audit-carrier-invoice/audit-carrier-invoice.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { InputsModule, NumericTextBoxModule } from '@progress/kendo-angular-inputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { AuditCarrierUpdateListComponent } from './audit-carrier-update-list/audit-carrier-update-list.component';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { FlagIconCountryPipe } from './pipes/flag-icon-country.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GridModule } from '@progress/kendo-angular-grid';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConvertToUnmatchedDialogComponent } from './convert-to-unmatched-dialog/convert-to-unmatched-dialog.component';
import { ShipmentQuoteHistoryComponent } from './shipment-quote-history/shipment-quote-history.component';
import { ShipmentQuoteChargeComponent } from './shipment-quote-charge/shipment-quote-charge.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuditQueueStatusUpdateComponent } from './audit-queue-status-update/audit-queue-status-update.component';
import { NotesAutoComponent } from './app-autocomplete/app-autocomplete';
import { AuditQueueNotesListComponent } from './audit-queue-notes-list/audit-queue-notes-list.component';
import { AddressEditDialogComponent } from './address-edit-dialog/address-edit-dialog.component';
import { InvoiceNoteComponent } from './invoice-note/invoice-note.component';
import { InvoiceNoteModalComponent } from './invoice-note-modal/invoice-note-modal.component';
import { InvoiceNotesComponent } from './invoice-notes/invoice-notes.component';
import { ShowErrorsComponent } from './show-errors/show-errors.component';
import { DocumentListEditComponent } from './document-list-edit/document-list-edit.component';
import { DocumentRenameModalContentComponent } from './document-rename-modal-content/document-rename-modal-content.component';
import { UploadModule } from '@progress/kendo-angular-upload';
import { ShipmentHistoryComponent } from './shipment-history/shipment-history.component';
import { AuditHistoryAuditListComponent } from './audit-history-audit-list/audit-history-audit-list.component';
import { LtlTrackingHistoryComponent } from './ltl-tracking-history/ltl-tracking-history.component';



@NgModule({
  declarations: [
    AddressViewComponent,
    ConversionPipe,
    FlagIconCountryPipe,
    ChargeTableComponent,
    AccordionComponent,
    AuditCarrierInvoiceComponent,
    SpinnerComponent,
    AuditCarrierUpdateListComponent,
    AlertMessageComponent,
    ConfirmDialogComponent,
    ConvertToUnmatchedDialogComponent,
    ShipmentQuoteHistoryComponent,
    ShipmentQuoteChargeComponent,
    AuditQueueStatusUpdateComponent,
    NotesAutoComponent,
    AuditQueueNotesListComponent,
    AddressEditDialogComponent,
    InvoiceNoteComponent,
    InvoiceNoteModalComponent,
    InvoiceNotesComponent,
    ShowErrorsComponent,
    DocumentListEditComponent,
    DocumentRenameModalContentComponent,
    ShipmentHistoryComponent,
    AuditHistoryAuditListComponent,
    LtlTrackingHistoryComponent
  ],
  imports: [
    CommonModule,
    DropDownsModule,
    DateInputsModule,
    DialogsModule,
    FormsModule,
    GridModule,
    ReactiveFormsModule,
    InputsModule,
    UploadModule,
    NumericTextBoxModule,
    NgbModule
  ],
  exports: [
    AddressViewComponent,
    ConversionPipe,
    FlagIconCountryPipe,
    ChargeTableComponent,
    AccordionComponent,
    AuditCarrierInvoiceComponent,
    SpinnerComponent,
    AuditCarrierUpdateListComponent,
    AlertMessageComponent,
    ConfirmDialogComponent,
    ConvertToUnmatchedDialogComponent,
    ShipmentQuoteHistoryComponent,
    ShipmentQuoteChargeComponent,
    AuditQueueStatusUpdateComponent,
    NotesAutoComponent,
    AuditQueueNotesListComponent,
    AddressEditDialogComponent,
    InvoiceNotesComponent,
    DocumentListEditComponent,
    ShipmentHistoryComponent,
    LtlTrackingHistoryComponent
  ],
  entryComponents: [
    ConfirmDialogComponent,
    ConvertToUnmatchedDialogComponent,
    AddressEditDialogComponent,
    InvoiceNoteModalComponent,
    DocumentRenameModalContentComponent
  ]
})
export class BgCommonModule { }
