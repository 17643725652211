import { AuthService } from '@/auth/auth.service';
import { CarrierResponse } from '@/models/Carrier';
import { priceSheetIDArray } from '@/models/Shipment';
import { Address, Charge, Item, Rate, ShipmentInvoiceAuditDTO, Markup } from '@/models/ShipmentInvoiceAudit';
import {
  DimensionRerate, ItemRerate, QuantityRerate,
  SelectedRate, ShipmentInvoiceAuditRerateDTO, WeightRerate
} from '@/models/ShipmentInvoiceAuditRerate';
import {
  AccessorialSave,
  AccessorialsEntity, AddressSave, DimensionSave, ItemSave,
  QuantitySave,
  WeightSave
} from '@/models/ShipmentInvoiceAuditSave';

import { CarrierService } from '@/services/carrier.service';
import { InvoiceCharge } from '@/services/InvoiceAudit';
import { LocalStorageService } from '@/services/localstorage.service';
import { ShipmentService } from '@/services/shipment.service';
import { StartupService } from '@/startup.service';
import { AlertMessageComponent } from '@/bg-common/alert-message/alert-message.component';
import { Globals } from '@/_shared/globals';
import { Helpers } from '@/_shared/helpers';
import { SpinnerComponent } from '@/bg-common/spinner/spinner.component';
import { CurrencyPipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import equal from 'fast-deep-equal';
import { switchMap } from 'rxjs/operators';
import { MarkupRuleResponse, Rate as BgRate, MarkupCharge, QuoteResponse } from '../../models/BgRating';
import { ShipmentUI, ShipmentUI as ui } from '../../pages/audit/Shipment.ui';
import { ShipmentInvoiceAuditUI } from '../../pages/shipment-invoice-audit/ShipmentInvoiceAudit.ui';
import { BgRatingService } from '../../services/bgRating.service';
import { EnterpriseService } from '../../services/enterprise.service';
import { BgRatingHelper } from '../bgRatingHelper';
import { AuditItemListComponent } from '../shipping-edit/audit-item-list/audit-item-list.component';
import { ReferencesEditComponent } from '../shipping-edit/references-edit/references-edit.component';
import { ShipmentRefTypes } from '../../_shared/ShipmentRefTypes';
import { ModalComponent } from '../../_shared/modal/modal.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'shipment-edit',
  templateUrl: './shipment-edit.component.html',
  styleUrls: ['./shipment-edit.component.scss'],
  providers: [CurrencyPipe]
})
export class ShipmentEditComponent implements OnInit {
  @Input()
  isAuditor: boolean;

  @Input()
  shipmentData: ShipmentInvoiceAuditUI.Shipment;

  @Input()
  markupRule: MarkupRuleResponse;

  @Output()
  onCustomerChargesUpdate: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onShipmentUpdate: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onReferencesUpdate: EventEmitter<ui.ReferenceUI[]> = new EventEmitter<ui.ReferenceUI[]>();

  @Output()
  onSuccessRerate: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(AuditItemListComponent, { static: false })
  auditItemListComponent: AuditItemListComponent;

  @ViewChild('referenceList', { static: false })
  referenceList: ReferencesEditComponent;

  @ViewChild(AlertMessageComponent, { static: true })
  alertMessage: AlertMessageComponent;

  @ViewChild(SpinnerComponent, { static: true })
  appSpinner: SpinnerComponent;

  @ViewChild(ModalComponent, { static: true }) modalComponent: ModalComponent;

  shipmentEditState: CollapsibleState = new CollapsibleState('Edit Shipment');

  get controls() {
    return this.carrierForm.controls;
  }

  helpers: Helpers = null;
  shipment: ShipmentInvoiceAuditDTO;
  shipmentRerateResultsDTO: any[];
  uiShipment: ui.Shipment = null;
  uiShipmentCopy: ui.Shipment = null;
  carrierList: CarrierResponse[] = null;
  controlCarrierList: CarrierResponse[] = null;
  controlServiceList: string[] = [];
  saveErrorList: string[] = [];
  carrierForm: FormGroup = null;
  freightClasses = ShipmentRefTypes.freightClasses;
  selectedfreightClass: number;
  shipmentID: number;
  primaryReference: string;

  isEditingAddress = false;
  openedRateError = false;
  pendingInvoice: any;
  isLoading: boolean;
  initialLoad: boolean;
  lastLength = 0;
  loadingMessage: string;
  carrierNameError: string;
  rateMessage: string;
  titleError: string;
  textError: string;
  isItemsDataloading: boolean;
  isLoadingCarriers: boolean;
  isRerating: boolean;
  isSaving: boolean;
  isRateFound: boolean;
  showAllReferences: boolean;
  editCustomerCharges: boolean;
  readOnlyCustomerChargesVisible: boolean;
  uplift: Markup;
  private spinnerTimeout = 0;
  readonly bgRatingHelper = new BgRatingHelper();
  public dataClass: any;
  shipmentAccessorialList = Globals.ShipmentAccessorials;
  pickupAccessorialList = Globals.PickupAccessorials;
  deliveryAccessorialList = Globals.DeliveryAccessorials;

  constructor(
    private startupService: StartupService,
    private pcShipmentService: ShipmentService,
    private pcFinanceService: CarrierService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private enterpriseService: EnterpriseService,
    private bgRatingService: BgRatingService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    // get the shipment id
    this.shipmentID = +this.route.snapshot.paramMap.get('shipmentid');
    this.loadingMessage = 'Please wait. Loading shipment information...';
    this.isItemsDataloading = true;
    this.showAllReferences = false;
    this.loadPage();

  }

  ngOnInit() {
    // are they allowed to view this page
    this.helpers = this.helpers == null ? new Helpers(this.authService) : this.helpers;
    if (!this.helpers.hasOperation('InvoiceAudit')) {
      alert("User Doesn't have the 'InvoiceAudit' operation");
    }
    this.isLoadingCarriers = false;
    this.initialLoad = true;
    this.isRerating = false;
    this.isRateFound = true;
    this.isSaving = false;

    this.setCustomerPricing();
    this.loadPedingInvoice();
  }

  get isShipmentAirOceanGroundFinalMile() {
    const mode = this.shipment.shipment.modeType.toLowerCase();
    return mode === 'air' || mode === 'ocean' || mode === 'intl ground' || mode === 'final mile';
  }

  setCustomerPricing() {
    const currentDate = new Date();
    this.bgRatingService.getMarkupRules(currentDate, this.shipmentData.accountNumber)
      .subscribe((markupRule) => {
        this.markupRule = markupRule;
      });
  }

  // Page events
  public savePage() {
    // was going to use promises but started to become overly complicated
    this.showSpinner(true, 'Saving rate...', true);
    this.isSaving = true;
    this.openedRateError = false;
    this.saveErrorList = [];
    this.saveOriginAddress()
      .then(() => {
        this.loadPage();
      });
  }

  public closeRateError() {
    this.openedRateError = false;
  }

  public onToggleReferences() {
    this.showAllReferences = !this.showAllReferences;
  }

  public onCarrierNamevalueChange(value) {
    if (value === undefined || value === null) {
      return;
    }

    this.carrierNameError = '';
    const index = this.carrierList.findIndex((i) => i.carrierName.toLowerCase() === value.trim().toLowerCase());
    if (index === -1) {
      this.uiShipment.resetCarrier();
      return;
    }

    this.setCarrier(this.carrierList[index]);
  }

  public onServiceLevelValueChange(value) {
    if (value === undefined || value === null) {
      return;
    }

    const index = this.controlServiceList.findIndex((i) => i.toLowerCase() === value.trim().toLowerCase());
    if (index === -1) {
      this.uiShipment.resetServiceName();
      if (this.startupService.showDebug) {
        console.log('Service level changed ' + this.uiShipment.serviceName);
      }
      return;
    }

    this.uiShipment.serviceName = this.controlServiceList[index];
    if (this.startupService.showDebug) {
      console.log('Service level changed ' + this.uiShipment.serviceName);
    }
  }

  public onLtlCarrierNameFilter(value) {
    value = value.trim();
    if (value.length > 3) {
      this.controlCarrierList = this.carrierList.filter((s) => s.carrierName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length === 3 && this.lastLength < value.length) {
      this.loadCarriers(value, null);
    } else if (value.length < 3) {
      this.controlCarrierList = [];
    }
    this.lastLength = value.length;
  }

  public searchInputKeyUp(e: KeyboardEvent): void {
    const value = this.carrierForm.get('carrierName').value;
    if (e.keyCode === 13 && value.length >= 3) {
      e.preventDefault();
      if (this.uiShipment.isLTL()) {
        this.onLtlCarrierNameFilter(value);
      } else {
        // this.onSearchTLCarriers();
      }
    }
  }

  public onResetCustomerCost(): void {
    // revert the changes back
    this.uiShipment.setCustomerCharges(this.shipment.rates[0].customerCharges);
    this.editCustomerCharges = false;
  }

  public onSaveCustomerCost(charges: InvoiceCharge[]): void {
    this.editCustomerCharges = false;
    this.showSpinner(true, 'Saving charges...', true);

    // grab all the data from the currently selected rate
    const saveRate = this.shipment.rates.find((obj) => obj.isSelected === true);

    // update the customer charges
    this.onCustomerChargesUpdate.emit(saveRate);

    // map charge types back to what ShippingAPI needs
    this.mapGridChargeTypes(saveRate);
  }

  // helpers
  public closeEditCustomerCost(): void {
    this.editCustomerCharges = false;
  }

  public addError(err: any, message: string) {
    if (this.startupService.showDebug) {
      console.log(err);
    }
    if (Array.isArray(err)) {
      const that = this;
      err.forEach(function (error) {
        that.saveErrorList.push(error);
      });
    } else {
      this.saveErrorList.push(message + ' ' + err);
    }
  }

  private updateUIModel() {
    this.uiShipmentCopy.carrierName = this.carrierForm.get('carrierName').value;
    this.uiShipmentCopy.serviceName = this.carrierForm.get('serviceName').value;
    this.uiShipmentCopy.origin.updateValues(this.uiShipment.origin);
    this.uiShipmentCopy.destination.updateValues(this.uiShipment.destination);
    this.uiShipmentCopy.accessorialsDelivery = this.uiShipment.accessorialsDelivery;
    this.uiShipmentCopy.accessorialsShipment = this.uiShipment.accessorialsShipment;
    this.uiShipmentCopy.accessorialsPickup = this.uiShipment.accessorialsPickup;
    this.uiShipmentCopy.items = { ...this.auditItemListComponent.records };
  }

  private setServiceList() {
    const services = this.shipment.rates.map((a) => a.service);
    const uniqueArray = services.filter(function (item, pos) {
      return services.indexOf(item) === pos;
    });
    this.controlServiceList = uniqueArray;
  }

  public validPage() {
    return (
      !this.isLoading &&
      !this.isRerating &&
      !this.isSaving &&
      !this.isEditingAddress &&
      !!this.auditItemListComponent &&
      this.auditItemListComponent.valid() &&
      this.carrierForm != null &&
      this.carrierForm.valid
    );
  }

  private setCarrier(carrier: CarrierResponse) {
    this.uiShipment.scac = carrier.scac;
    this.uiShipment.carrierName = carrier.carrierName;
  }

  private loadCarriers(value: string, scac: string) {
    if (this.uiShipment.isLTL() || this.uiShipment.isOcean()) {
      if (scac) {
        this.loadLTLSCACCarriers(scac);
      } else {
        this.loadLTLCarriers(value);
      }
    } else {
      //this.loadTruckloadCarriers(value);
    }
  }

  private isDirtyOriginAddress(): boolean {
    if (this.uiShipmentCopy === null) {
      return false;
    }
    return !equal(this.uiShipmentCopy.origin, this.uiShipment.origin);
  }

  private isDirtyDestinationAddress(): boolean {
    if (this.uiShipmentCopy === null) {
      return false;
    }
    return !equal(this.uiShipmentCopy.destination, this.uiShipment.destination);
  }

  private isCarrierDirty(): boolean {
    if (this.carrierForm == null) {
      return false;
    }
    return this.uiShipmentCopy.carrierName !== this.carrierForm.get('carrierName').value;
  }

  private isItemsDirty(): boolean {
    if (this.auditItemListComponent == null) {
      return false;
    }

    const gridItems = this.auditItemListComponent.records;
    if (this.uiShipmentCopy.items.length !== gridItems.length) {
      return true;
    }
    return !equal(this.uiShipmentCopy.items, gridItems);
  }

  private isServiceDirty(): boolean {
    if (this.carrierForm == null) {
      return false;
    }
    return this.uiShipmentCopy.serviceName !== this.carrierForm.get('serviceName').value;
  }

  private isAccesorialsDirty(): boolean {
    const areDifferent = (left: ShipmentUI.AccessorialUI[], right: ShipmentUI.AccessorialUI[]) => {
      left = left || [];
      right = right || [];

      if (left.length != right.length) {
        return true;
      }

      return left.some((l) => right.find((r) => r.code === l.code) == undefined);
    };

    return (
      areDifferent(this.uiShipmentCopy.accessorialsDelivery, this.uiShipment.accessorialsDelivery) ||
      areDifferent(this.uiShipmentCopy.accessorialsShipment, this.uiShipment.accessorialsShipment) ||
      areDifferent(this.uiShipmentCopy.accessorialsPickup, this.uiShipment.accessorialsPickup)
    );
  }

  public isDirtyPage(): boolean {
    if (this.uiShipmentCopy === null) {
      return false;
    }

    return (
      this.isDirtyOriginAddress() ||
      this.isDirtyDestinationAddress() ||
      this.isCarrierDirty() ||
      this.isItemsDirty() ||
      this.isServiceDirty() ||
      this.isAccesorialsDirty()
    );
  }

  private showErrors(
    title: string = 'Saving Shipment Error',
    text: string = 'There were errors when trying to save this shipment. The areas below have not been saved.'
  ) {
    this.titleError = title;
    this.textError = text;
    this.isSaving = false;
    this.openedRateError = true;
    this.isRerating = false;
    this.showSpinner(false);
  }

  private stopLoading() {
    this.isLoadingCarriers = false;
    this.isLoading = false;
    this.isItemsDataloading = false;
    this.showSpinner(false);
  }

  private showSpinner(show: boolean, text: string = '', modal: boolean = false) {
    this.appSpinner.loading = show;
    this.appSpinner.modal = modal;
    this.appSpinner.text = text;
  }

  private objectFlip(obj) {
    const ret = {};
    Object.keys(obj).forEach((key) => {
      ret[obj[key]] = key;
    });
    return ret;
  }

  private mapGridChargeTypes(selectedRate: Rate) {
    // flip the objects keys and properties
    const typeMapping = this.objectFlip(Globals.FinanceChargeTypeMapping);

    // loop through the charges
    for (const charge of selectedRate.customerCharges) {
      charge.type = typeMapping[charge.type];
    }
  }

  private mapShippingApiChargeTypes(charges: Charge[]) {
    // loop through the charges
    for (const charge of charges) {
      charge.type = Globals.ShippingChargeTypeMapping[charge.type];
    }
  }

  // map calls
  private createAddress(addressUI: ShipmentUI.AddressUI, addressDTO: Address): AddressSave {
    // copy the sent in fields to a 'save object'
    const cloneAddress = (({ addressID, dropoffItems, pickupItems, sequence, contactFax, contactEmail, ...o }) => o)(addressDTO);
    // copy the new values from the page to the save object (deleting unwanted ones)
    const newAddress = Object.assign({}, cloneAddress, addressUI);
    delete newAddress.id;
    newAddress.sequence = addressDTO.sequence;
    newAddress.contactPhone = (!newAddress.contactPhone || newAddress.contactPhone.length === 0) ? '555-555-5555' : newAddress.contactPhone;
    return newAddress;
  }

  private createItem(record: ShipmentUI.ItemUI): ItemSave {
    const saveItem = new ItemSave();

    saveItem.itemID = record.id;
    saveItem.quantity = new QuantitySave();
    saveItem.quantity.value = record.quantity;
    saveItem.quantity.uom = record.quantityUOM;
    saveItem.quantity.numberOfPieces = record.pieces;
    saveItem.quantity.piecesUom = record.piecesUOM;
    saveItem.weight = new WeightSave();
    saveItem.weight.value = record.weight;
    saveItem.weight.uom = record.weightUOM;
    saveItem.dimension = new DimensionSave();
    saveItem.dimension.type = '';
    saveItem.dimension.width = record.width;
    saveItem.dimension.length = record.length;
    saveItem.dimension.height = record.height;
    saveItem.dimension.uom = record.dimensionUOM;
    saveItem.description = record.description;
    saveItem.freightClass = record.freightClass;
    saveItem.nmfc = record.nmfc;

    return saveItem;
  }

  private updateItem(record: ShipmentUI.ItemUI, itemDTO: Item): ItemSave {
    // copy the sent in fields to a 'save object'
    // delete itemDTO.itemID;
    const saveItem = (({ ...o }) => o)(itemDTO) as ItemSave;

    // copy the sent in fields to a 'save object'
    saveItem.quantity.value = record.quantity;
    saveItem.quantity.uom = record.quantityUOM;
    saveItem.quantity.numberOfPieces = record.pieces;
    saveItem.quantity.piecesUom = record.piecesUOM;
    saveItem.weight.value = record.weight;
    saveItem.weight.uom = record.weightUOM;
    saveItem.dimension.width = record.width;
    saveItem.dimension.length = record.length;
    saveItem.dimension.height = record.height;
    saveItem.dimension.uom = record.dimensionUOM;
    saveItem.description = record.description;
    saveItem.freightClass = record.freightClass;
    saveItem.nmfc = record.nmfc;
    return saveItem;
  }

  // service calls
  private saveOriginAddress(): Promise<any> {
    this.saveErrorList = [];
    if (this.isDirtyOriginAddress()) {
      const saveAddress = this.createAddress(this.uiShipment.origin, this.shipment.addresses[0]);
      if (this.startupService.showDebug) {
        console.log('Origin Address Save: ', saveAddress);
      }
      return this.pcShipmentService.saveShipmentAddressInvoiceAudit(this.shipmentID, this.uiShipment.origin.id, saveAddress)
        .toPromise()
        .then((data) => {
          return this.saveDestinationAddress();
        })
        .catch((err) => {
          this.addError(err, 'Error saving Origin address.');
          return this.saveDestinationAddress();
        });
    } else {
      return this.saveDestinationAddress();
    }
  }

  private saveDestinationAddress(): Promise<any> {
    if (this.isDirtyDestinationAddress()) {
      const saveAddress = this.createAddress(this.uiShipment.destination, this.shipment.addresses[1]);
      if (this.startupService.showDebug) {
        console.log('Destination Address Save: ', saveAddress);
      }
      return this.pcShipmentService.saveShipmentAddressInvoiceAudit(this.shipmentID, this.uiShipment.destination.id, saveAddress)
        .toPromise()
        .then((data) => {
          return this.saveAccessories();
        })
        .catch((err) => {
          this.addError(err, 'Error saving Destination address.');
          this.saveAccessories();
        });
    } else {
      return this.saveAccessories();
    }
  }

  private saveAccessories(): Promise<any> {
    if (this.isAccesorialsDirty()) {
      const accessorialSave = new AccessorialSave();
      accessorialSave.accessorials = [];
      this.uiShipment.getAccessorials().forEach((record) => {
        const item = new AccessorialsEntity();
        item.code = record.code;
        item.name = record.name;
        accessorialSave.accessorials.push(item);
      });
      if (this.startupService.showDebug) {
        console.log('Accessorials Save: ', accessorialSave);
      }

      return this.pcShipmentService.saveAccessorialsInvoiceAudit(this.shipmentID, accessorialSave)
        .toPromise()
        .then((data) => {
          return this.saveItems();
        })
        .catch((err) => {
          this.addError(err, 'Error saving Accessorials.');
          return this.saveItems();
        });
    } else {
      return this.saveItems();
    }
  }

  private updateGridItems({ data, selected }: any = false) {
    if (selected) {
      this.uiShipment.items.forEach((element) => element.freightClass = selected);
      this.shipmentRerateResultsDTO = [];
      this.alertMessage.showAlertMessage('Updated all the items to the specific class', 'Success');
      this.modalComponent.closeModal();
      this.auditItemListComponent.setRecordsChangeDetection();
    }
  }

  private saveItems(): Promise<any> {
    if (this.isItemsDirty()) {
      let saveItem = null;
      const itemList: ItemSave[] = [];
      const gridItems = this.auditItemListComponent.records;

      // loop for all the items in the grid
      gridItems.forEach((uiItem) => {
        if (uiItem.id < -1) {
          saveItem = this.createItem(uiItem);
          itemList.push(saveItem);
          if (this.startupService.showDebug) {
            console.log('Update Item Save: ', saveItem);
          }
        } else {
          // find the items in the main list
          const modelItem = this.shipment.items.find((obj) => obj.itemID === uiItem.id);
          if (modelItem) {
            saveItem = this.updateItem(uiItem, modelItem);
            itemList.push(saveItem);
            if (this.startupService.showDebug) {
              console.log('Update Item Save: ', saveItem);
            }
          }
        }
      });

      return this.pcShipmentService.saveShipmentItemsInvoiceAudit(this.shipmentID, itemList)
        .toPromise()
        .then((data) => {
          this.shipment.items = data.items;
          this.uiShipment.setItems(data.items);

          // if there are errors dont move forward
          if (this.saveErrorList.length !== 0) {
            this.showErrors();
          } else {
            return this.saveRate();
          }
        },
          (err) => {
            this.addError(err, 'Error saving Shipping Items.');
            this.showErrors();
          }
        );
    } else {
      // if there are errors dont move forward
      if (this.saveErrorList.length !== 0) {
        this.showErrors();
      } else {
        return this.saveRate();
      }
    }
  }

  public onRatePage(markup?: Markup) {
    if (markup) {
      this.uplift = markup;
    }
    this.bgReRate();
  }

  public getQuote(): Observable<QuoteResponse> {
    //#region enableActualDateRerate FF cleanup
    if (this.startupService.enableActualDateRerate) {
      this.shipment.addresses[0].earliestDate = this.shipment.addresses[0].actualDate || this.shipment.addresses[0].earliestDate;
    }
    //#endregion
    return this.bgRatingService
      .getQuote(this.bgRatingHelper.createQuoteRequest(this.uiShipment, this.shipment, this.auditItemListComponent, true));
  }


  public saveRates(rates: Rate[]): void {
    this.shipmentRerateResultsDTO = [rates];
    this.savePage();
  }

  onthirdPartyRate(): void {
    this.thirdPartyRate();
  }

  bgReRate() {
    this.isRerating = true;
    this.isRateFound = true;
    this.showSpinner(true, 'Rerating...', true);
    this.parseWeightToInteger();

    //#region enableActualDateRerate FF cleanup
    if (this.startupService.enableActualDateRerate) {
      this.shipment.addresses[0].earliestDate = this.shipment.addresses[0].actualDate || this.shipment.addresses[0].earliestDate;
    }
    //#endregion

    this.bgRatingService
      .getQuote(this.bgRatingHelper.createQuoteRequest(this.uiShipment, this.shipment, this.auditItemListComponent))
      .subscribe(
        (response) => {
          const error = response.errorMessage && response.errorMessage.length > 0;
          if (this.startupService.showDebug) {
            console.log('BGR JSON: ', JSON.stringify(response));
          }
          const rates = !error ? this.bgRatingHelper.mapRates(response) : [];
          if (this.startupService.showDebug) {
            console.log('Mapped Rates JSON: ' + JSON.stringify(rates));
            console.log('Rate criteria: scac ' + this.uiShipment.scac + ' PriceSheet BGR_Blanket Service ' + this.uiShipment.serviceName);
          }
          const rate = rates.find(
            (rate) =>
              rate.scac === this.uiShipment.scac &&
              priceSheetIDArray.some((priceSheetID) => priceSheetID === rate.priceSheetID) &&
              rate.service.toLocaleLowerCase() === this.uiShipment.serviceName.toLocaleLowerCase()
          );

          const benchmark = rates.find(
            r => r.scac.toLocaleLowerCase() === 'benchmark' &&
              priceSheetIDArray.some(priceSheetID => priceSheetID === r.priceSheetID) &&
              r.service.toLocaleLowerCase() === this.uiShipment.serviceName.toLocaleLowerCase()
          );

          if (error || rate === undefined) {
            if (error) {
              console.log(response.errorMessage, 'Error while rerating.');
            }

            this.showErrors('Obtaining Rate Error', 'There were errors when trying to rerate this shipment.');
            this.isRateFound = false;
            this.isRerating = false;
            this.showSpinner(false);

            return;
          }

          if (this.uplift.amount != null && this.uplift.type != null) {
            const parameters = {
              scac: rate.scac,
              accountNumber: this.uiShipment.accountNumber,
              originCountryCode: this.uiShipment.origin.countryCode,
              originPostalCode: this.uiShipment.origin.postalCode,
              originStateProvince: this.uiShipment.origin.stateProvince,
              destinationCountryCode: this.uiShipment.destination.countryCode,
              destinationPostalCode: this.uiShipment.destination.postalCode,
              destinationStateProvince: this.uiShipment.destination.stateProvince,
              upliftAmount: this.uplift.amount,
              upliftType: this.uplift.type,
              //#region enableActualDateRerate FF cleanup
              //shipmentDate: this.shipment.addresses[0].actualDate || this.shipment.addresses[0].earliestDate,
              shipmentDate: this.shipment.addresses[0].earliestDate,
              //#endregion
              mode: rate.mode,
              currencyCode: rate.currencyCode,
            };

            const charges = rate.carrierCharges.map((charge) => {
              return <MarkupCharge>{
                amount: charge.amount,
                description: charge.description,
                type: this.bgRatingHelper.getBGChargeType(charge.type),
                subType: this.bgRatingHelper.getBGChargeSubType(charge.type),
                code: charge.ediCode,
              };
            });

            this.bgRatingService.getRateUplift(this.bgRatingHelper.createUpliftRequest(parameters, this.uiShipment.items, charges)).subscribe((result) => {
              if (result != null) {
                rate.customerCharges = result.rates[0].customerRate.charges;
                rate.markups = [];
                rate.markups.push(this.uplift);

                rate.isSelected = true;
                this.rateMessage = 'New rate was found.';
                this.isRerating = false;
                this.isRateFound = true;
                this.showSpinner(false);

                this.shipmentRerateResultsDTO = [rate];
                if (!!benchmark) {
                  this.shipmentRerateResultsDTO.unshift(benchmark);
                }

                return this.savePage();
              } else {
                rate.isSelected = true;
                this.rateMessage = 'New rate was found.';
                this.isRerating = false;
                this.isRateFound = true;
                this.showSpinner(false);

                this.shipmentRerateResultsDTO = [rate];
                if (!!benchmark) {
                  this.shipmentRerateResultsDTO.unshift(benchmark);
                }

                return this.savePage();
              }
            });
          } else {

            rate.isSelected = true;
            this.rateMessage = 'New rate was found.';
            this.isRerating = false;
            this.isRateFound = true;
            this.showSpinner(false);

            this.shipmentRerateResultsDTO = [rate];
            if (!!benchmark) {
              this.shipmentRerateResultsDTO.unshift(benchmark);
            }

            this.savePage();
          }
        },
        (error) => {
          this.saveErrorList = [];
          this.addError(error, 'Error while rerating.');
          this.showErrors('Obtaining Rate Error', 'There were errors when trying to rerate this shipment.');
        }
      );
  }


  public bgRerateCharge(): void {
    this.isRerating = true;
    this.isRateFound = true;
    this.showSpinner(true, 'Rerating...', true);
    this.parseWeightToInteger();

    //#region enableActualDateRerate FF cleanup
    if (this.startupService.enableActualDateRerate) {
      this.shipment.addresses[0].earliestDate = this.shipment.addresses[0].actualDate || this.shipment.addresses[0].earliestDate;
    }
    //#endregion

    this.bgRatingService
      .getQuote(this.bgRatingHelper.createQuoteRequest(this.uiShipment, this.shipment, this.auditItemListComponent))
      .subscribe(
        (response) => {
          const error = response.errorMessage && response.errorMessage.length > 0;
          if (this.startupService.showDebug) {
            console.log('BGR JSON: ', JSON.stringify(response));
          }
          const rates = !error ? this.bgRatingHelper.mapRates(response) : [];
          if (this.startupService.showDebug) {
            console.log('Mapped Rates JSON: ' + JSON.stringify(rates));
            console.log('Rate criteria: scac ' + this.uiShipment.scac + ' PriceSheet BGR_Blanket Service ' + this.uiShipment.serviceName);
          }
          const rate = rates.find(
            (rate) =>
              rate.scac === this.uiShipment.scac &&
              priceSheetIDArray.some((priceSheetID) => priceSheetID === rate.priceSheetID) &&
              rate.service.toLocaleLowerCase() === this.uiShipment.serviceName.toLocaleLowerCase()
          );
          if (error || rate === undefined) {
            if (error) {
              console.log(response.errorMessage, 'Error while rerating.');
            }

            this.showErrors('Obtaining Rate Error', 'There were errors when trying to rerate this shipment.');
            this.isRateFound = false;
            this.isRerating = false;
            this.showSpinner(false);

            return;
          }

          this.rateMessage = 'New rate was found.';
          this.isRerating = false;
          this.isRateFound = true;
          this.showSpinner(false);

          this.shipmentRerateResultsDTO = [rate];

          this.saveRateCharge();
        },
        (error) => {
          this.saveErrorList = [];
          this.addError(error, 'Error while rerating.');
          this.showErrors('Obtaining Rate Error', 'There were errors when trying to rerate this shipment.');
        }
      );
  }

  private saveRateCharge(): void {
    this.mapShippingApiChargeTypes(this.shipmentRerateResultsDTO[0].customerCharges);
    this.mapShippingApiChargeTypes(this.shipmentRerateResultsDTO[0].carrierCharges);

    // call the service
    this.pcShipmentService.reRateCharge(this.shipmentID, this.shipmentRerateResultsDTO[0]).subscribe(
      (data) => {
        this.isRerating = false;
        this.isSaving = false;
        this.showSpinner(false);
        this.rateMessage = 'New rate was found and saved.';

        // update the screen
        this.uiShipment.carrierTotal = this.shipmentRerateResultsDTO[0].carrierTotal;
        this.uiShipment.customerTotal = this.shipmentRerateResultsDTO[0].customerTotal;

        // update the customer charges
        this.uiShipment.setCustomerCharges(data.rates[0].customerCharges);

        this.onCustomerChargesUpdate.emit(this.uiShipment.charges);
        this.onShipmentUpdate.emit(this.uiShipment);

        // copy the updates of the UI to the 'copy' of the UI model
        this.updateUIModel();

        // update the local storage (for refreshing parent audit page)
        this.localStorage.set(this.shipmentID.toString(), Date.now().toString());
        this.loadPage();
      },
      (err) => {
        this.saveErrorList = [];
        this.addError(err, 'Error saving Shipping Items.');
        this.showErrors('Saving Rate Error', 'There were errors when trying to save the rate for this shipment.');
      }
    );
  }

  updateRates(rate: BgRate) {
    console.log(rate);
  }

  private thirdPartyRate() {
    this.isRerating = true;
    this.isRateFound = true;
    this.showSpinner(true, 'Rerating...', true);

    this.shipmentRerateResultsDTO = null;
    const reRateDTO = new ShipmentInvoiceAuditRerateDTO();

    // addresses
    reRateDTO.primaryReference = this.uiShipment.bol;
    reRateDTO.accountNumber = this.uiShipment.accountNumber;
    reRateDTO.addresses = [];
    reRateDTO.addresses.push({
      earliestDate: this.shipment.addresses[0].earliestDate,
      city: this.uiShipment.origin.city,
      state: this.uiShipment.origin.stateProvince,
      zip: this.uiShipment.origin.postalCode,
      country: this.uiShipment.origin.countryCode,
      sequence: 1,
    });
    reRateDTO.addresses.push({
      earliestDate: this.shipment.addresses[1].earliestDate,
      city: this.uiShipment.destination.city,
      state: this.uiShipment.destination.stateProvince,
      zip: this.uiShipment.destination.postalCode,
      country: this.uiShipment.destination.countryCode,
      sequence: 2,
    });

    // accessorials
    reRateDTO.accessorials = [];
    reRateDTO.accessorials.push(...this.uiShipment.getAccessorialCodes());

    // items
    let index = 1;
    reRateDTO.items = [];

    this.auditItemListComponent.records.forEach((record) => {
      const item = new ItemRerate();
      item.freightClass = record.freightClass.toString();
      item.sequence = index++;

      item.weight = new WeightRerate();
      item.weight.units = record.weightUOM;
      item.weight.value = record.weight;

      item.quantity = new QuantityRerate();
      item.quantity.units = record.quantityUOM;
      item.quantity.value = record.quantity;

      item.dimensions = new DimensionRerate();
      item.dimensions.units = record.dimensionUOM;
      item.dimensions.height = record.height;
      item.dimensions.length = record.length;
      item.dimensions.width = record.width;
      reRateDTO.items.push(item);
    });

    // add scac and service
    reRateDTO.selectedRate = new SelectedRate();
    reRateDTO.selectedRate.scac = this.uiShipment.scac;
    reRateDTO.selectedRate.service = this.controls.serviceName.value;
    if (this.startupService.showDebug) {
      console.log('reRate Sent: ', reRateDTO);
    }

    // call the service
    this.pcShipmentService.reRateShipment(reRateDTO).subscribe(
      (data) => {
        this.shipmentRerateResultsDTO = data;

        // did we get a rate?
        if (this.shipmentRerateResultsDTO == null || this.shipmentRerateResultsDTO.length === 0) {
          this.isRateFound = false;
          this.isRerating = false;
          this.showSpinner(false);
          return;
        }

        // save the rate
        this.rateMessage = 'New rate was found.';
        this.savePage();
      },
      (err) => {
        this.saveErrorList = [];
        this.addError(err, 'Error saving Shipping Items.');
        this.showErrors('Obtaining Rate Error', 'There were errors when trying to rerate this shipment.');
      }
    );
  }

  private saveRate(): Promise<any> {
    this.shipmentRerateResultsDTO.forEach(x => {
      x.customerCharges.forEach(charge => {
        charge.type = this.mapChargeType(charge.subType);
        if (charge.code) {
          charge.ediCode = charge.code;
        }
      });
      x.carrierCharges.forEach(charge => {
        charge.type = this.mapChargeType(charge.subType);
      });
    });

    // call the service
    return this.pcShipmentService
      .saveShipmentRate(this.shipmentID, this.shipmentRerateResultsDTO)
      .toPromise()
      .then(data => {
        this.isRerating = false;
        this.isSaving = false;
        this.showSpinner(false);
        this.rateMessage = 'New rate was found and saved.';
        let index = this.shipmentRerateResultsDTO.findIndex(x => x.scac === this.uiShipment.scac);

        // update the screen
        if (index != -1) {
          this.uiShipment.carrierTotal = this.shipmentRerateResultsDTO[index].carrierTotal;
          this.uiShipment.customerTotal = this.shipmentRerateResultsDTO[index].customerTotal;
        }

        // update the customer charge
        this.onCustomerChargesUpdate.emit(this.uiShipment.charges);
        this.uiShipment.setCustomerCharges(data.rates[0].customerCharges);
        this.onShipmentUpdate.emit(this.uiShipment);

        // copy the updates of the UI to the 'copy' of the UI model
        this.updateUIModel();

        // update the local storage (for refreshing parent audit page)
        this.localStorage.set(this.shipmentID.toString(), Date.now().toString());
      })
      .catch(
        (err) => {
          this.saveErrorList = [];
          this.addError(err, 'Error saving Shipping Items.');
          this.showErrors('Saving Rate Error', 'There were errors when trying to save the rate for this shipment.');
        }
      );
  }

  private loadPage() {
    this.isLoading = true;
    this.pcShipmentService.getShipmentByIDInvoiceAudit(this.shipmentID).subscribe(
      (data) => {
        this.shipment = data;
        this.uiShipment = new ui.Shipment(data, true);
        this.uiShipmentCopy = new ui.Shipment(data, false);

        this.pcShipmentService.getEnterprise(this.shipment.enterprise.id).subscribe((response) => { });

        this.setServiceList();
        this.showAllReferences = this.uiShipment.references.length <= 4;

        if (this.startupService.showDebug) {
          console.log('Shipment API: ', this.shipment);
          console.log('Transformed: ', this.uiShipment);
        }

        // should we load the carriers
        if (this.uiShipment.scac || (this.uiShipment.carrierName && this.uiShipment.carrierName.length > 2)) {
          this.loadCarriers(this.uiShipment.carrierName, this.uiShipment.scac);
        } else {
          this.createForm();
        }

        this.stopLoading();
        this.onSuccessRerate.emit(true);
      },
      (error) => {
        this.loadingMessage = 'Error Loading Page: ' + error.message;
        this.alertMessage.showAlertMessage('Error loading the audit page', 'Error');
      }
    );
  }



  private loadPedingInvoice(): void {
    this.pcShipmentService.getPendingInvoiceID(this.shipmentData.invoiceID)
      .pipe(switchMap(item => this.pcShipmentService.getPendingInvoice(item)))
      .subscribe((data) => this.pendingInvoice = data);
  }

  private loadLTLCarriers(value: string) {
    // are we already searching
    if (this.appSpinner.loading === true) {
      return;
    }
    this.showSpinner(true, 'Loading carriers...');
    this.isLoadingCarriers = true;
    this.pcFinanceService.getLtlCarriers(value).subscribe(
      (data) => {
        this.stopLoading();
        this.controlCarrierList = this.carrierList = data;
        this.carrierList.sort((a, b) => (a.carrierName > b.carrierName ? 1 : -1));
        if (this.carrierForm == null) {
          this.createForm();
        }
      },
      (error) => {
        this.stopLoading();
        this.loadingMessage = 'Error Loading Page: ' + error.message;
        this.alertMessage.showAlertMessage('Error loading the LTL carriers', 'Error');
        if (this.carrierForm == null) {
          this.createForm();
        }
      }
    );
  }

  private loadLTLSCACCarriers(scac: string) {
    if (this.appSpinner.loading === true) {
      return;
    }
    this.showSpinner(true, 'Loading carriers...');
    this.isLoadingCarriers = true;
    this.pcFinanceService.getLtlSCACCarriers(scac).subscribe(
      (data) => {
        this.stopLoading();

        this.controlCarrierList = this.carrierList = data;
        if (data.length === 1) {
          this.uiShipment.carrierName = data[0].carrierName;
        }
        if (this.carrierForm == null) {
          this.createForm();
        }
      },
      (error) => {
        this.stopLoading();
        this.loadingMessage = 'Error Loading Page: ' + error.message;
        this.alertMessage.showAlertMessage('Error loading the LTL carriers', 'Error');
        if (this.carrierForm == null) {
          this.createForm();
        }
      }
    );
  }

  /// form functions
  private createForm() {
    // if we didnt find it in the list we need to clear it out.
    if (
      this.uiShipment.carrierName != null &&
      (this.controlCarrierList == null ||
        this.controlCarrierList.length === 0 ||
        this.uiShipment.carrierName !== this.controlCarrierList[0].carrierName)
    ) {
      if (this.uiShipment.carrierName !== '') {
        this.carrierNameError = `Carrier '${this.uiShipment.carrierName}' not found.`;
        this.uiShipment.carrierName = '';
      }
    }

    this.carrierForm = this.formBuilder.group({
      carrierName: [this.uiShipment.carrierName, [Validators.required, this.carrierNameValidator.bind(this)]],
      serviceName: [this.uiShipment.serviceName, [Validators.required]],
    });

    // validate the form
    this.helpers.markAllAsTouchedDirty(this.carrierForm);
    this.helpers.updateAllValidity(this.carrierForm);
  }

  public carrierNameValidator(control: FormControl) {
    if (!this.uiShipment.isLTL()) {
      if (this.carrierForm) {
        // no list so defintily not found
        if (this.controlCarrierList.length === 0) {
          return { invalidCarrier: true };
        }

        // find it in the list?
        const value = this.carrierForm.get('carrierName').value.trim().toLowerCase();
        const index = this.controlCarrierList.findIndex((i) => i.carrierName.toLowerCase() === value);
        if (index === -1) {
          return { invalidCarrier: true };
        }

        // only need to force the section on the first page load
        if (this.initialLoad) {
          this.setCarrier(this.controlCarrierList[index]);
          this.initialLoad = false;
        }
      }
    }
    return null;
  }

  public referencesSaveComplete(text: string): void {
    window.clearTimeout(this.spinnerTimeout);
    this.showSpinner(false);
    this.alertMessage.showAlertMessage(text, 'Success');

    const references = this.referenceList.theRecords as ui.ReferenceUI[];
    this.onReferencesUpdate.emit(references);
  }

  public referencesSaveError(text: string): void {
    window.clearTimeout(this.spinnerTimeout);
    this.showSpinner(false);
    this.alertMessage.showAlertMessage(text, 'Error');
  }

  public referencesSaveStart(text: string): void {
    window.clearTimeout(this.spinnerTimeout);
    // delay showing spinner by 1/4 second for quick saves to minimize flashing
    this.spinnerTimeout = window.setTimeout(() => {
      this.showSpinner(true, text, true);
    }, 250);
  }

  public onOriginAddressChange(address: ShipmentUI.AddressUI) {
    this.alertMessage.showAlertMessage('The origin address has been updated', 'Success');
    this.uiShipment.origin.updateValues(address);
  }

  public onDestinationAddressChange(address: ShipmentUI.AddressUI) {
    this.alertMessage.showAlertMessage('The destination address has been updated', 'Success');
    this.uiShipment.destination.updateValues(address);
  }

  public onEditingAddress(value: boolean) {
    this.isEditingAddress = value;
  }
  private parseWeightToInteger() {
    // Weight values should always be rounded up when rating
    let items = this.uiShipment.items;
    for (let i = 0; i < items.length; i++) {
      items[i].weight = Math.ceil(items[i].weight);
    }
  }

  private mapChargeType(subtype: string): string {
    switch (subtype.toLocaleUpperCase()) {
      case 'LINEHAUL':
        return 'ITEM';
      case 'DISCOUNT':
        return 'DISCOUNT';
      case 'ADJUSTMENT':
        return 'MG_MINMAX_ADJ';
      case 'SMCADJUSTMENT':
      case 'MINIMUM':
        return 'SMC_MIN_ADJ';
      case 'DEFICIT':
        return 'DEFICIT';
      case 'FUELSURCHARGE':
        return 'ACCESSORIAL_FUEL';
      default:
        return 'ACCESSORIAL';
    }
  }

  openModalReclassItem(data): void {
    const optionsModal = {
      width: 650,
      height: 200,
      title: 'Reclass All Items'
    }
    this.selectedfreightClass = null;
    this.dataClass = data;
    this.modalComponent.showConfirmation(optionsModal)
  }
}

class CollapsibleState {
  constructor(public title: string = '', public show = true, public collapsed = true) { }

  onCollapse() {
    this.collapsed = !this.collapsed;
  }
}
