import {
  Component,
  OnDestroy,
  OnInit,
  Input,
  Output,
  ViewChild,
  Renderer2,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { CollectionNote } from '../../services/Collection';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { Helpers } from '../../_shared/helpers';
import { AuthService } from '../../auth/auth.service';
import { ConfirmationDialogService } from '../../_shared/confirmation-dialog/confirmation-dialog.service';

// common constants
const matches = (el, selector) => (el.matches || el.msMatchesSelector).call(el, selector);

@Component({
  selector: 'app-collection-note-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './collection-note-list.component.html',
})
export class CollectionNoteListComponent implements OnInit, OnDestroy {
  @ViewChild('theGrid', { static: false })
  private recordGrid: GridComponent;

  _theRecords: CollectionNote[];
  get theRecords(): CollectionNote[] {
    return this._theRecords;
  }

  @Input('theRecords')
  set theRecords(value: CollectionNote[]) {
    this._theRecords = value;
    this.setRecords();
  }

  @Output()
  remove = new EventEmitter<CollectionNote>();

  @Output()
  insert = new EventEmitter<CollectionNote>();

  @Output()
  update = new EventEmitter<CollectionNote>();

  gridView: GridDataResult;
  private docClickSubscription: any = null;
  formGroup: FormGroup;
  tempRecordID: number;
  editedRowIndex: number;
  helpers: Helpers;
  sort: SortDescriptor[] = [
    {
      field: 'datecreated',
      dir: 'asc',
    },
  ];

  constructor(
    private changeDetector: ChangeDetectorRef,
    private authService: AuthService,
    private renderer: Renderer2,
    private confirmationDialogService: ConfirmationDialogService
  ) {}

  ngOnInit() {
    this.tempRecordID = -1;
    this.helpers = new Helpers(this.authService);

    this.setRecords();
    if (!this.docClickSubscription) {
      this.docClickSubscription = this.renderer.listen('document', 'click', this.onDocumentClick.bind(this));
    }
  }

  // events
  public ngOnDestroy(): void {
    if (!this.docClickSubscription) {
      this.docClickSubscription();
    }
  }
  public cellClickHandler({ isEdited, dataItem, rowIndex }): void {
    if (
      isEdited ||
      (this.formGroup && !this.formGroup.valid) ||
      (this.formGroup && this.formGroup.value.id < 0) ||
      !this.helpers.hasOperation('CanWorkCollection')
    ) {
      return;
    }

    this.updateRecord();
    this.formGroup = this.createFormGroup(dataItem);
    this.editedRowIndex = rowIndex;
    this.recordGrid.editRow(rowIndex, this.formGroup);
  }
  public onRemove(event, theRecord) {
    // confirm
    const that = this;
    this.confirmationDialogService
      .confirm('Confirm Deletion', 'Do you really want to delete this record?', 'Delete', 'Cancel')
      .then(function (result) {
        if (result === true) {
          that.removeRecord(theRecord);
        }
      })
      .catch((err) => console.log(err));
  }
  public onNew() {
    if (this.formGroup) {
      return;
    }

    // new data model
    this.tempRecordID--;
    const dataItem = new CollectionNote();
    dataItem.id = this.tempRecordID;

    this.formGroup = this.createFormGroup(dataItem);
    this.editedRowIndex = 0;

    // add one to the list and edit it
    this.theRecords.unshift(dataItem);
    this.setRecords();
    this.recordGrid.editRow(0, this.formGroup);
  }
  public onInsert(event, id) {
    if (this.formGroup.invalid) {
      return;
    }
    // update the dates and wipe out temp id
    const record = this.theRecords.find(({ id }) => id === this.formGroup.value.id);
    Object.assign(record, this.formGroup.value);
    record.id = 1000; // forces the remove to show up

    // tell the parent that the record has been added to the grid.
    this.closeEditor();
    this.insert.emit(record);
  }
  public onCancel(event, id) {
    this.closeEditor();
    this.theRecords = this.theRecords.filter((obj) => obj.id !== id);

    this.setRecords();
  }
  protected sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.setRecords();
  }
  private onDocumentClick(e: any): void {
    if (
      this.formGroup &&
      this.formGroup.valid &&
      this.formGroup.get('id').value > 0 &&
      !matches(e.target, '#thenotegrid tbody *, #thenotegrid .k-grid-toolbar .k-button')
    ) {
      this.updateRecord();
    }
  }

  // helpers
  private removeRecord(theRecord) {
    // remove the record from the grid.
    this.closeEditor();
    this.theRecords = this.theRecords.filter((obj) => obj !== theRecord);

    // tell the parent that the record has been removeed from the grid.
    this.remove.emit(theRecord);
  }
  private updateRecord(): void {
    if (this.formGroup && !this.formGroup.invalid) {
      // update the record
      const record = this.theRecords.find(({ id }) => id === this.formGroup.value.id);
      Object.assign(record, this.formGroup.value);

      // tell the parent that the record has been added to the grid.
      this.closeEditor();
      this.update.emit(record);
    }
  }
  public hasOperation(operation: string) {
    return this.helpers.hasOperation(operation);
  }
  private closeEditor(): void {
    if (!this.recordGrid) {
      return;
    }
    this.recordGrid.closeRow(this.editedRowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }
  private setRecords() {
    this.gridView = {
      data: orderBy(this.theRecords, this.sort),
      total: this.theRecords.length,
    };
  }

  createFormGroup = (dataItem) =>
    new FormGroup({
      id: new FormControl(dataItem.id),
      noteText: new FormControl(dataItem.noteText, Validators.required),
    });
}
