import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { AuthService } from './../auth/auth.service';
import { StartupService } from './../startup.service';
import { catchError, map } from 'rxjs/operators';
import { ShipmentHistory } from '../services/ShipmentHistory';
import { ShipmentQuoteHistoryResponse } from '@/models/ShipmentQuoteHistoryResponse';
@Injectable({
  providedIn: 'root',
})
export class ShipmentHistoryService {
  shipmentApiHeaders: HttpHeaders;
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': 'http://localhost:4202', // -->Add this line
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
      'Access-Control-Allow-Headers': '*',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };
  public shipmentHistoryBook: ShipmentHistory[];
  constructor(private http: HttpClient, private startupService: StartupService, private authService: AuthService) {
    this.shipmentApiHeaders = new HttpHeaders({
      //      EnterpriseID: '7',
      EnterpriseID:
        this.authService.BlueShipUser && this.authService.BlueShipUser.enterpriseID
          ? this.authService.BlueShipUser.enterpriseID.toString()
          : null,
      UserID:
        this.authService.BlueShipUser && this.authService.BlueShipUser.userID ? this.authService.BlueShipUser.userID.toString() : null,
      UserName: this.authService.BlueShipUser ? this.authService.BlueShipUser.name : null,
      /*
        'Access-Control-Allow-Origin': 'http://localhost:4200', // -->Add this line
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
        'Access-Control-Allow-Headers': '*',
        'Content-Type': 'application/json',
        Accept: 'application/json'
        */
    });
  }

  getHistory(id: number): Observable<ShipmentHistory[]> {
    return this.http
      .get<ShipmentHistory[]>(`${this.startupService.financeAPIUrl}v2/shipments/${id}/audit-history`, {
        headers: this.httpOptions.headers,
      })
      .pipe(
        map((data) => (this.shipmentHistoryBook = data)),
        catchError(this.handleError)
      );
  }

  getQuoteHistory(shipmentID: number): Observable<ShipmentQuoteHistoryResponse> {
    return this.http.get<ShipmentQuoteHistoryResponse>(`${this.startupService.shipmentApiUrl}v2/${shipmentID}/shipment/quotehistory`, {
      headers: this.shipmentApiHeaders,
    });
  }

  private handleError(res: HttpErrorResponse) {
    return observableThrowError(res.error || 'Server error');
  }
}
