//Request
export class Item {
  freightClass: string;
  weight: number;
  weightUom: string;
  length: number;
  width: number;
  height: number;
  quantity: number;
  itemUnitType: string;
}

export class Options {
  skipMarkup: boolean;
  skipSave: boolean;
  skipDistance: boolean;
  skipFilter: boolean;
  skipDynamicLtlRating: boolean;
  skipLtlRating: boolean;
  skipVolumeRating: boolean;
  includeProposal: boolean;
}

export class QuoteRequest {
  accountNumber: string;
  originPostal: string;
  originStateProvince: string;
  originCity: string;
  originCountry: string;
  destinationPostal: string;
  destinationStateProvince: string;
  destinationCity: string;
  destinationCountry: string;
  shipmentDate: Date;
  carriers: Array<string>;
  services: Array<string>;
  items: Array<Item>;
  options: Options;
}

export class MarkupCharge {
  amount: number;
  description: string;
  code: string;
  type: string;
  subType: string;
}

export class RateToMarkup {
  description: string;
  scac: string;
  mode: string;
  charges: MarkupCharge[];
  currencyCode: string;
}

export class QuoteMarkupRequest {
  accountNumber: string;
  shipmentDate: Date;
  originPostal: string;
  originStateProvince: string;
  originCountry: string;
  destinationPostal: string;
  destinationStateProvince: string;
  destinationCountry: string;
  items: Item[];
  ratesToMarkup: RateToMarkup[];
}

export class UpliftRequest {
  accountNumber: string;
  shipmentDate: Date;
  originPostal: string;
  originStateProvince: string;
  originCountry: string;
  destinationPostal: string;
  destinationStateProvince: string;
  destinationCountry: string;
  upliftAmount: number;
  upliftType: string;
  items: Item[];
  ratesToMarkup: RateToMarkup[];
}

/**
 * Response for BG rating endpoint /PricingConfiguration/markuprule
 */
export class MarkupRuleResponse {
  markup: number = 0;
  markupType: string;
  upliftLinehaulOnly: boolean;
  isOpenBook: boolean;
}

//Response
export interface TerminalCodes {
  destination: string;
  origin: string;
}

export interface Charge {
  amount: number;
  description: string;
  code: string;
  type: string;
  subType: string;
  rate: number;
  rateQualifier: string;
  quantity: number;
  weight: number;
  freightClass: number;
  fakFreightClass: number;
  isMin: boolean;
  isMax: boolean;
}

export interface ServiceLevel {
  analogousID: string;
  type: string;
  description: string;
  carrierQuoteNumber: string;
  estimatedDelivery: Date;
  transitDays: number;
  totalCharge: number;
  serviceLevelCharges: Charge[];
}

export interface CarrierRate {
  analogousID: string;
  description: string;
  carrierQuoteNumber: string;
  totalCharge: number;
  totalChargeInUSD?: number;
  note: string;
  charges: Charge[];
  serviceLevels: ServiceLevel[];
}

export class Markup {
  index: string;
  amount: number;
  type: string;
  markupIndexID: number;
}

export interface CustomerRate {
  analogousID: string;
  accountNumber: string;
  customerName: string;
  totalChargeInUSD?: number;
  description: string;
  totalCharge: number;
  negativeUpliftFloorPercent: number;
  charges: Charge[];
  serviceLevels: ServiceLevel[];
  markups: Markup[];
}

export interface BillingAddress {
  companyName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  stateProvince: string;
  postalCode: string;
  country: string;
}

export interface Rate {
  source: string;
  scac: string;
  mode: string;
  rating: number;
  carrierName: string;
  carrierAccountNumber: string;
  carrierAccountNumberDisplay: string;
  contractID: number;
  contractName: string;
  serviceDays: number;
  billedWeight: number;
  terminalcodes: TerminalCodes;
  carrierRate: CarrierRate;
  customerRate: CustomerRate;
  currencyCode?: string;
  customerThirdRate?: CustomerRate;
  billingAddress: BillingAddress;
  errorMessage: string;
  isExcluded: boolean;
  excludeReason: string;
  estimatedDelivery: any;
  discountType: string;
  maxLiability: number;
  serviceOrigin: string;
  serviceDestination: string;
  customerCostAdjustment?: number;
}

export class QuoteResponse {
  id: string;
  distance: number;
  errorMessage?: string;
  rates: Rate[];
}

